import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getSubmittedOptions, getSubmittedStatus } from '../firebase';
import { useNavigate } from 'react-router-dom';

const Submitted = () => {
  const [user, loading] = useAuthState(auth);
  const [options, setOptions] = useState(['1', '2', '3', '4', '5']);
  const [submitted, setSubmitted] = useState(true);
  const navigation = useNavigate();

  useEffect(() => {
    const fetchSubmittedStatus = async (user) => {
      const submittedStatus = await getSubmittedStatus(user);
      setSubmitted(submittedStatus);
    };

    const fetchSubmittedOptions = async (user) => {
      const submittedOptions = await getSubmittedOptions(user);
      setOptions(submittedOptions);
    };

    console.log(loading);
    // if loading do nothing
    if (loading) return;
    // if not logged in return to login screen
    if (!user) return navigation('/');

    fetchSubmittedStatus(user);

    if (!submitted) {
      navigation('/form');
    }
    fetchSubmittedOptions(user);
  }, [user, loading, navigation, submitted]);

  // useEffect(() => {
  //   const fetchSubmittedOptions = async (user) => {
  //     const submittedOptions = await getSubmittedOptions(user);
  //     setOptions(submittedOptions);
  //   };
  //   // if loading do nothing
  //   if (loading) return;
  //   // if not logged in return to login screen
  //   if (!user) return;
  //   fetchSubmittedOptions(user);
  // }, [user, loading]);

  return (
    <>
      <h1 className='text-lg font-bold pb-4'>
        Thanks for submitting your options
      </h1>
      <ol className='list-decimal pl-6'>
        {options.map((option, key) => {
          return <li key={key}>{option}</li>;
        })}
      </ol>
      <h4 className='mt-4 text-gray-600'>
        Please note that where there is a concern or an imbalance regarding your
        choice of curriculum, we will arrange to meet with you and your family
        to discuss option choices.
      </h4>
    </>
  );
};

export default Submitted;
