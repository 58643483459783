import OptionsForm from './components/OptionsForm';
import Submitted from './components/Submitted';
import Login from './components/Login';
import Logout from './components/Logout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/form' element={<OptionsForm />} />
        <Route path='/submitted' element={<Submitted />} />
        <Route path='/logout' element={<Logout />} />
      </Routes>
    </Router>
  );

  // <OptionsForm />;
}
