/* TODO -- need to upgrade to version 9 of firebase API */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBTu_kotayRpt5KKFKpcS5rsfsXtS97rRU',
  authDomain: 'rhs-options.firebaseapp.com',
  projectId: 'rhs-options',
  storageBucket: 'rhs-options.appspot.com',
  messagingSenderId: '405659910015',
  appId: '1:405659910015:web:b546119bc40b64e9c0244a',
  measurementId: 'G-4BKN4K1X40',
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection('users')
      .where('uid', '==', user.uid)
      .get();
    if (query.docs.length === 0) {
      await db.collection('users').add({
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    //alert(err.message);
  }
};

const setSubmittedStatus = async (user, options) => {
  try {
    const query = await db
      .collection('options')
      .where('uid', '==', user.uid)
      .get();
    if (query.docs.length === 0) {
      await db.collection('options').add({
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        options: options,
      });
    }
  } catch (err) {
    console.error(err);
    //alert(err.message);
  }
};

const getSubmittedStatus = async (user) => {
  try {
    const query = await db
      .collection('options')
      .where('uid', '==', user.uid)
      .get();
    if (query.docs.length > 0) {
      return true;
    }

    return false;
  } catch (err) {
    console.error(err);
    //alert(err.message);
  }
};

const getSubmittedOptions = async (user) => {
  try {
    const query = await db
      .collection('options')
      .where('uid', '==', user.uid)
      .get();
    if (query.docs.length > 0) {
      const data = await query.docs[0].data();
      console.log(data.options);
      return data.options;
    }

    return false;
  } catch (err) {
    console.error(err);
    //alert(err.message);
  }
};

const logout = () => {
  console.log('signing out');
  auth.signOut();
};

export {
  auth,
  db,
  signInWithGoogle,
  logout,
  setSubmittedStatus,
  getSubmittedStatus,
  getSubmittedOptions,
};
