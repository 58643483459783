import React from 'react';

const ValidationBar = ({ messages }) => {
  const numberOfMessagesLimit = 4;
  const messagesToDisplay = messages.slice(-numberOfMessagesLimit);
  return (
    <div>
      <ul>
        {messagesToDisplay.map((message, index) => {
          return (
            <li
              className='text-red-600 text-lg block bg-slate-100 p-2'
              key={index}
            >
              {message}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ValidationBar;
