export const y9Options2022 = [
  'Art & Design',
  'Business Studies',
  'Cambridge National Engineering Design',
  'Child Development (Cambridge National L1/2)',
  'Computer Science',
  'Dance',
  'Design and Technology',
  'BTEC Digital Information Technology',
  'Drama',
  'Enterprise (BTEC)',
  'Food Preparation & Nutrition',
  'Geography',
  'History',
  'Media Studies',
  'Music (BTEC)',
  'Photography',
  'Physical Education',
  'Religious Studies',
  'Sports Science (Cambridge National L1/2)',
];
export const tutorGroups = ['9AG', '9EC', '9EF', '9HT', '9JSG', '9NM', '9TM'];

export const pathways = {
  Science: [
    'Combined Science (2 GCSEs)',
    '3 Separate Sciences (Please note entry requirements for Separate Sciences)',
  ],
};

export const eligibleToChooseCS = [
  'coveruser@range.sefton.sch.uk',
];
