import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithGoogle } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { LoginIcon } from '@heroicons/react/outline';

function Login() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate('/form');
  }, [user, loading, navigate]);
  return (
    <div className='login'>
      <h2 className='mb-4 text-lg'>
        Please log in using your school account -- username@range.sefton.sch.uk
      </h2>
      <div>
        <button
          onClick={signInWithGoogle}
          type='button'
          className='inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <LoginIcon className='h-8 w-8' aria-hidden='true' />
          Login with Google
        </button>
      </div>
    </div>
  );
}
export default Login;
