import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../firebase';

const Logout = () => {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    // if loading do nothing
    if (loading) return;
    // if not logged in show default options for the header
    if (user) {
      logout();
    }
  }, [user, loading]);

  return (
    <>
      <h1 className='text-xl pb-4'>Logged out</h1>
      <h4 className='text-gray-600 text-lg pb-4'>
        Please close the browser window to fully log out.
      </h4>
      <h4 className='text-gray-600'>
        If you are being signed into a Google account that doesn't belong to
        Range High School you may need to{' '}
        <a
          className='text-blue-700 underline'
          href='https://accounts.google.com/Logout'
        >
          manage the Google accounts on your device
        </a>{' '}
        to add your school account.
      </h4>
    </>
  );
};

export default Logout;
