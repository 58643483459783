import * as yup from 'yup';

let schema = yup.object().shape({
  'First Name': yup.string().required().min(2),
  'Last Name': yup.string().required().min(2),
  tutor: yup.string().required().min(3).max(4),
  'pathway-Science': yup
    .string()
    .oneOf(
      [
        'Combined Science (2 GCSEs)',
        '3 Separate Sciences (Please note entry requirements for Separate Sciences)',
      ],
      'Science pathway is a required field'
    )
    .required('Science pathway is a required field'),
  selectedSubjects: yup
    .array()
    .min(5, 'You must select at least 5 subjects')
    .max(5, 'You may only select 5 subjects')
    .required(),
});

export const validate = async (formState) => {
  let validationMessages = [];
  await schema
    .validate(formState, { abortEarly: false })
    .then(function (valid) {
      validationMessages = [];
    })
    .catch(function (err) {
      validationMessages = err.errors;
    });
  return validationMessages;
};
