import React from 'react';
import PropTypes from 'prop-types';

const SubjectSelect = ({
  subjects,
  inputLabel,
  inputName,
  selected,
  onChange,
  value,
  disabled,
  alreadySelected,
  canChooseCS,
}) => {
  const subjectsToDisplay = subjects.filter(
    (subject) => !alreadySelected.includes(subject)
  );

  return (
    <>
      <label
        className='block text-sm font-medium text-gray-700'
        htmlFor={inputName}
      >
        {inputLabel}
      </label>
      <select
        disabled={disabled}
        name={inputName}
        selected={selected}
        onChange={onChange}
        value={value}
        defaultValue='select-option'
        className='w-80'
      >
        <option disabled value='select-option'>
          -- select an option --
        </option>
        {subjectsToDisplay.map((option) => {
          return (
            <option
              disabled={!canChooseCS && option === 'Computer Science'}
              key={option}
              value={option}
            >
              {option}
            </option>
          );
        })}
      </select>
    </>
  );
};

SubjectSelect.propTypes = {
  subjects: PropTypes.array.isRequired,
  inputLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
};

export default SubjectSelect;
